<template>
  <div class="app page-bg">
    <esports-header />
    <main class="container-page">
      <router-view :key="$route.fullPath"></router-view>
    </main>
    <esports-footer />
  </div>
</template>

<script>
export default {
  name: "UserTemplate",
};
</script>
